import React from 'react'
import Bouton from '../../../components/Bouton/Bouton';

const Livre=(props)=> {
    return (
        <>
        <td>{props.titre}</td>
        <td>{props.auteur}</td>
        <td>{props.nbPages}</td>
        <td><Bouton typeBtn="btn-warning" clic={props.modification}>Modificatiom</Bouton></td>
        <td><Bouton typeBtn="btn-danger" clic={props.suppression}>Suppression</Bouton></td>
       </>
    )
}

export default Livre
