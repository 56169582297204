import React,{Component} from 'react';
import TitreH1 from './components/Titres/TitreH1';
import Bouton from './components/Bouton/Bouton';
import Livres from './containers/Livres/Livres';

class App extends Component {

  state={
    ajoutLivre:false
  }

  handleAjoutLivre=()=>{
    this.setState((oldState,props)=>{
      return {ajoutLivre:!oldState.ajoutLivre}
    })
  }

  render(){

    return (
      <div className="container">
  
        <TitreH1>Page listant les livres</TitreH1>
        <Livres ajoutLivre={this.state.ajoutLivre} fermerAjoutLivre={()=>{this.setState({ajoutLivre:false})}}/>
        <Bouton typeBtn="btn-success" cssAj="w-100" clic={this.handleAjoutLivre}>
          {!this.state.ajoutLivre?"Ajouter":"Fermer ajout livre"}
          </Bouton>
        {/* <Bouton typeBtn="btn-warning" clic={()=>console.log("Modifier")}>Modifier</Bouton>
        <Bouton typeBtn="btn-danger" clic={()=>console.log("Supprimer")}>Supprimer</Bouton> */}
        
      </div>
    );
  }
  
}

export default App;
