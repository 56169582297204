import React, { Component } from 'react';
import Livre from './Livre/Livre';
import FormulaireAjout from './FormulaireAjout/FormulaireAjout';
import FormulaireModification from './FormulaireModification/FormulaireModification';
import Alert from '../../components/Alert/Alert';

class Livres extends Component {

         state={
             livres:[
                 {id:1,titre:"L'enfant noir",auteur:"Camara Laye",nbPages:200},
                 {id:2,titre:"Une si longue lettre",auteur:"Mariame Bah",nbPages:300},
                 {id:3,titre:"Crapo brousse",auteur:"Thierno Monenen Bo",nbPages:350},
                 {id:4,titre:"L'etrange wangrin",auteur:"Amadou Hampate Bah",nbPages:500},
                 {id:5,titre:"L'epope Manding",auteur:"Djibril Tamsir Nian",nbPages:450}
             ],
             lastIdLivre:5,
             idLivreModifier:0,
             alertMessage:null
         }

    handleSuppressionLivre=(id)=>{
        const livreIndexTab=this.state.livres.findIndex((l)=>{
            return l.id===id
        })

        const newLivres=[...this.state.livres];
        newLivres.splice(livreIndexTab,1);
        this.setState({
            livres:newLivres,
            alertMessage:{
                message:'Suppression effectuee',
                type:'alert-danger'
            }
        })
    }

    handleAjoutLivre=(titre,auteur,nbPages)=>{
         
       const newLivre={id:this.state.lastIdLivre+1,titre:titre,auteur:auteur,nbPages:nbPages};
       const newListLivre=[...this.state.livres];
       newListLivre.push(newLivre);
       this.setState((oldstate,props)=>{
           return{
               livres:newListLivre,
               lastIdLivre:oldstate.lastIdLivre+1,
               alertMessage:{
                   message:'Ajout effectue',
                   type:'alert-success'
                }
           }
       })

       this.props.fermerAjoutLivre()
    }

    handleModificationLivre=(id,titre,auteur,nbPages)=>{
              const caseLivre=this.state.livres.findIndex((l)=>{
                  return l.id===id
              })
              const newLivre ={id,titre,auteur,nbPages}
              const newListe=[...this.state.livres]
              newListe[caseLivre]=newLivre
              this.setState({
                  livres:newListe,
                  idLivreModifier:0,
                  alertMessage:{
                      message:'Modification effectuee',
                      type:'alert-warning'
                    }
              })
            }

    render() {
        return (
            <>
           {this.state.alertMessage && <Alert typeAlert={this.state.alertMessage.type}>{this.state.alertMessage.message}</Alert>} 
            <table className="table text-center">
                <thead>
                    <tr className="table-dark">
                        <th>Titre</th>
                        <th>Auteur</th>
                        <th>Nombre de pages</th>
                        <th colSpan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.livres.map((livre)=>{
                        if (livre.id !==this.state.idLivreModifier){

                   return( 
                    <tr key={livre.id}>
                        <Livre
                        titre={livre.titre}
                        auteur={livre.auteur}
                        nbPages={livre.nbPages}
                        suppression={()=>this.handleSuppressionLivre(livre.id)}
                        modification={()=>this.setState({idLivreModifier:livre.id})}
                        
                        />
                    </tr>
                   )
                        }else{
                            return (
                            <tr key={livre.id}>
                            <FormulaireModification
                             id={livre.id}
                             titre={livre.titre}
                             auteur={livre.auteur}
                             nbPages={livre.nbPages}
                             validationModification={this.handleModificationLivre}
                            
                            />
                            </tr>
                            )
                        }

                    })}
                    
                </tbody>

            </table>
            {this.props.ajoutLivre && <FormulaireAjout validation={this.handleAjoutLivre}/>}
            </>
        )
    }
}

export default Livres
