import React from 'react'

 const Alert=(props)=> {
     const mesClassCss=`alert ${props.typeAlert}`
    return (
        <div className={mesClassCss} role="alert">
             {props.children}
        </div>
    )
}

export default Alert
